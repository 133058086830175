/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";

 @import "~bootstrap/scss/bootstrap"; 
 @import "assets/scss/button.scss";
 @import "assets/scss/color.scss";
 @import "assets/scss/mixin.scss";
 @import "assets/scss/media.scss";

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}
h1, h2, h3, h4{
    position: relative;
}
.mb-40{
  margin-bottom: 40px;
  @include mobile-xs(){
    margin-bottom: 1.5rem;
  }
}
.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

html, body { 
  height: 100%;
  // color: $primary-text;
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.4px;
  @include mobile-xs(){
    font-size: 11px;
  }
}
body { 
  margin: 0; 
  font-family: "Poppins", sans-serif;
}
ul{
  list-style: none;
  padding-left: 0;
}
.mt-50{
  margin-top: 50px;
}
.bg-light{
  background-color: $bg-light;
}
.bg-dark{
  background-color: $accent-color !important;
  color: #dfeff9;
}
.btn-learn{
  text-transform: uppercase;
  // font-size: 12px;
  // line-height: 26px;
  font-weight: 500;
  position: relative;
  color: $accent-color;
 &::before{
  display: inline-block;
  content: '';
  width: 16px;
  height: 1px;
  margin-right: 10px;
  vertical-align: middle;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: $accent-color;
 }
 &:hover{
   color: darken($accent-color, 30);
   &::before{
     width: 20px;
     height: 2px;
   }
 }
}
.font-md{
  font-size: 16px;
  line-height: 30px !important;
}

.section-header{
  color: $second-color;
  text-align: center;
  
    h2{
      // background-color: #fff;
      position: relative;
      text-transform: capitalize;
      font-feature-settings: "smcp";
      font-variant: small-caps;
      font-weight: 800;
      letter-spacing: 1px;
      font-size: 3rem;
      @include mobile-xs(){
        font-size: 2rem;
      }
      .title{
        font-size: 2.5rem;
        font-weight: 700;
        position: relative;
        @include mobile-xs (){
          font-size: 1.5rem;
        }
        span{
          background-color: #fff;
          position: relative;
          width: 100%;
          display: inline-block;
          @include mobile-xs(){
            background-color: #fff;
            padding: 0.5rem;
          }
          &:before{
            position: absolute;
            left: 0;
            top: auto;
            height: 1px ;
            content: "";
            width: 100%;
            background-color: #e01a76;
            bottom: 0;
          }
        }
        
    }
    span{
      // background-color: #fff;
      position: relative;
      &:before{
        position: absolute;
        left: 0;
        top: auto;
        height: 3px;
        content: "";
        width: 100%;
        background-color: #047ac0;
        z-index: 1;
        bottom: 3px;
        border-radius: 50%;
      }
    }
    // &:before{
    //   position: absolute;
    //   left: 0;
    //   top: 50%;
    //   height: 1px ;
    //   content: "";
    //   width: 40%;
    //   background-color: #e01a76;
    //   z-index: -1;
    // }
    // &::after{
    //   position: absolute;
    //   left: auto;
    //   top: 50%;
    //   height: 1px ;
    //   content: "";
    //   width: 40%;
    //   right: 0;
    //   background-color: #e01a76;
    //   z-index: -1;
    // }
    
  }
  .sub-title{
    font-size: 1rem;
    margin-top: 1.5rem;
    color: #000000;
    text-align: center;
    font-weight: 400;
    
  }
}

.info-text {
  padding: 50px 0;
  text-align: center;
  @include mobile-xs(){
    padding: 20px 0;
  }
}



ul{
  &.list {
    list-style: none;
    padding-left: 0;
    margin: 0;
    li{
      padding-left: 2rem;
    line-height: 30px;
    position: relative;
    &::before{
      background-color: $accent-color;
      width: 15px;
      line-height: 10px;
      height: 3px;
      position: absolute;
      left: 0;
      top: 14px;
      content: "";
    }
    }
  }
}

.inside-banner {
  background: #047ac0;
  position: relative;
  // background: url(assets/images/mark-page.png) #047ac0 100% center;
  // &::before{
  //   height: 100%;
  //   width: 100%;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   content: "";
  //   background-color: $accent-color;
  // }

  .page-header {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    h1{
      margin-bottom: 0;
      font-size: 2.5rem;
    }
    h5{
      font-size: 1rem;
      margin-bottom: 0;
    }
}

}

.sub-header {
  padding: 0.5rem 1rem;
  .heading{
    h3{
      font-weight: 900;
      color: #0366d6;
    }
  }
}
.custom-data-grid{
  height: calc(100vh - 130px);
  .data-grid-body{
      height: calc(100vh - 130px);
      width: 100%;
  }
  
}
ul{
  &.controler-btn-group{
    list-style: none;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    li{
      margin-left: 0.2rem;
      margin-right: 0.2rem;
      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
      }
      .btn-md{
        font-size: 90%;
        padding: 0.3rem 1rem;
        border-radius: 2px;
        &.btn-alert{
          background-color: #9c9c9c;
          border: 0;
          color: #fff;
        }

      }
    }
  }
}




