
@mixin  mobile-xs {
    @media only screen and (max-width:576px){
        @content;
    }   
}
@mixin mobile-md {
    @media only screen and (max-width:768px){
        @content;
    }   
}
@mixin tablet-xs {
    @media only screen and (max-width:992px){
        @content;
    }   
}
@mixin tablet-md {
    @media only screen and (max-width:1024px){
        @content;
    }   
}
@mixin tablet-md {
    @media only screen and (max-width:1199px){
        @content;
    }   
}
@mixin desktop-md {
    @media only screen and (max-width:1200px){
        @content;
    }   
}
@mixin desktop-lg {
    @media only screen and (max-width:1440px){
        @content;
    }   
}